import React from "react"
import InfoMenu from "../components/InfoMenu"
import Cards from "../components/Cards"
import {
  Container,
  HeadlineContainer,
  HeroHeader,
  StickyContentContainer,
  StickyContent,
  TextBlock,
  Spacing,
  MinSpacing,
  BackgroundContainer,
  TextSegment,
  SmSpacing,
} from "../components/layout-components"
import { graphql } from "gatsby"
import { asBg } from "../util/image"
import { StaticImage } from "gatsby-plugin-image"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { Helmet } from "react-helmet"
import Footer from "../components/Footer"
import ApiIcon from "../lottie/Expertise/Icn_CustomAPI.json"
import Authentifizierung from "../lottie/Expertise/Icn_Authentifizierung.json"
import OAuth2 from "../lottie/Expertise/Icn_OAuth2.json"
import Websocket from "../lottie/Expertise/Icn_WebSocket.json"
import ZeroDowntime from "../lottie/Expertise/Icn_ZeroDowntime.json"
import SchemaFirst from "../lottie/Expertise/Icn_SchemaFirst.json"
import Datenbanken from "../lottie/Expertise/Icn_Datenbanken.json"
import Kompetenzen from "../lottie/Expertise/Icn_Kompetenzen.json"
import KomponentenVernetzen from "../lottie/Expertise/Icn_KomponentenVernetzen.json"
import SSLVerschluesselung from "../lottie/Expertise/Icn_SSLVerschluesselung.json"
import TypscriptReactClient from "../lottie/Expertise/Icn_TypscriptReactClient.json"

const Expertise: React.FC<{ data: any }> = ({ data }) => {
  const { headerBackground } = data

  const { t } = useTranslation()

  return (
    <Container>
      <Helmet>
        <title>Garage51 | {t("Expertise")}</title>
      </Helmet>

      <HeroHeader {...asBg(headerBackground)}>
        <HeadlineContainer align="left">
          <h1>
            <span className="subHeadline">{t("Unser")} </span>
            {t("Stack")}.
            <br />
            <span className="subHeadline">{t("Ihre")} </span>
            {t("Lösung")}.
          </h1>
        </HeadlineContainer>
      </HeroHeader>

      <TextBlock align="left" margin="0">
        <div>
          <p>
            {t(
              "Ob es sich um eine Website oder eine App handelt, um interne Tools, Cloudanwendungen oder um komplexe Systeme mit Redundanzen zur Steuerung von Industrieparks, unser Team aus erstklassigen Webentwicklern und Softwareingenieuren kann eine auf Ihre Bedürfnisse zugeschnittene Lösung erstellen."
            )}
          </p>
          <p>
            {t(
              "Das Entwicklungsteam arbeitet nach methodischen Praktiken und agilen Prinzipien, um sicherzustellen, dass Ihr digitales Produkt schnell und ohne Qualitätseinbußen geliefert wird."
            )}
          </p>
        </div>
      </TextBlock>

      <MinSpacing />

      <StickyContentContainer align="left" id="Technologien">
        <TextSegment
          align="left"
          width="45rem"
          colorTheme="primary"
          headline={t("Technologien")}
          text={t(
            "Dabei legen wir großen Wert auf das sinnvolle Einsetzen von neusten Technologien. Altbekanntes und scheinbar Verlässliches ist im Prozess einer digitalen Transformation kein Vorteil. Ältere Systeme und die Maxime „Never change a running system“ führen meistens zu hohen Kosten für Instandhaltungen und Anpassungen an neue Bedürfnisse."
          )}
          secondText={t(
            "Wir wählen die Technologien und unser Stack so, dass wir jederzeit auf etwaige Kurswechsel oder Strategieanpassungen reagieren können, ohne wertvolle Zeit zu verlieren."
          )}
        ></TextSegment>
        <StickyContent align="left" width="49rem">
          <StaticImage src="../images/technologien.jpg" alt="" />
        </StickyContent>
      </StickyContentContainer>

      <MinSpacing />

      <Cards
        id="modern-architecture"
        className="expertise"
        title={t("Moderne Architektur-Standards")}
        items={[
          {
            animation: SchemaFirst,
            label: t("Schema-First Design auf Backend- und API-Ebene"),
          },
          {
            animation: SSLVerschluesselung,
            label: t("Automatische SSL-Verschlüsselung für jedes Produkt"),
          },
          {
            animation: Kompetenzen,
            label: t("Kompetenzen in TypeScript, Go, Kotlin und Swift"),
          },
          {
            animation: KomponentenVernetzen,
            label: t("GraphQL, REST und gRPC zur Vernetzung von Komponenten"),
          },
          {
            animation: Datenbanken,
            label: t("Dokumenten- und SQL-Datenbanken"),
          },
          {
            animation: TypscriptReactClient,
            label: t("Typescript-basierte React Clients"),
          },
        ]}
      />

      <SmSpacing />

      <InfoMenu
        id="integration"
        marginBefore="medium"
        marginAfter="medium"
        header="Integration und DevOps"
        items={[
          {
            label: t("Zero-Downtime"),
            text: t("Upgrades und Deployment neuer Features"),
            anim: ZeroDowntime,
          },
          {
            label: t("Websocket Integration"),
            text: t(
              "Integration von Websockets, Hooks und Direktanbindung externer APIs"
            ),
            anim: Websocket,
          },
          {
            label: t("Granulare & Custom APIs"),
            text: t(
              "Granulare APIs über GraphQL und Custom REST APIs auf Anfrage"
            ),
            anim: ApiIcon,
          },
          {
            label: t("Authentifizierung"),
            text: t("Zentrale Authentifizierung von Nutzern über JWTs"),
            anim: Authentifizierung,
          },
          {
            label: t("OAuth2 Flows"),
            text: t("Verschiedene OAuth2 Flows zur einfachen Anbindung"),
            anim: OAuth2,
          },
        ]}
      />

      <MinSpacing />

      <StickyContentContainer align="right" id="Sicherheit">
        <TextSegment
          align="left"
          width="45rem"
          colorTheme="primary"
          headline={t("Sicherheit und Datenschutz")}
          text={t(
            "Ein für unsere Kunden und uns sehr wichtiger Aspekt ist die Sicherheit. Aus diesem Grund setzen wir auf native Entwicklungen. Zum einen im Hinblick auf die Stabilität der teilweise hochkomplexen Systeme und in Bezug auf die neusten Datenschutzbestimmungen. Durch nativ entwickelte Anwendungen können wir Stabilität und reibungslose Updatezyklen gewährleisten."
          )}
          secondText={t(
            "Unser Team besetzen wir nur durch ausgebildete und erfahrene Entwickler. Zum Thema Datenschutz arbeiten wir mit eigenen Anwälten oder mit Anwälten unserer Kunden zusammen."
          )}
        ></TextSegment>
        <StickyContent align="right" width="49rem">
          <StaticImage src="../images/datenschutz.jpg" alt="" />
        </StickyContent>
      </StickyContentContainer>

      <Spacing />

      <BackgroundContainer>
        <StickyContentContainer align="leftTop" id="Komplettbetreuung">
          <TextSegment
            width="45rem"
            align="leftTop"
            headline={t("DSGVO und Ihre Daten")}
            list={[
              t("PII-freies Logging im gesamten System"),
              t(
                "DSGVO-konforme Speicherung von Nutzerdaten auf Deutschen Servern"
              ),
              t(
                "Einheitliche, anonymisierte IDs für jeden Nutzer, mit denen eine systemweite Löschung durchgeführt werden kann"
              ),
              t("Anonymisierung von Daten und Ressourcen"),
              t(
                "Keine Speicherung von Nutzerdaten bei Drittanbietern oder externen Services"
              ),
              t("Ausführliches Auditing und Logs für alle Einheiten im System"),
            ]}
          ></TextSegment>
          <StickyContent align="leftTop" width="49rem">
            <StaticImage src="../images/dsgvo.jpg" alt="dsgvo" />
          </StickyContent>
        </StickyContentContainer>
      </BackgroundContainer>
      <Footer />
    </Container>
  )
}

export default Expertise

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "expertise"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    headerBackground: file(
      relativePath: { eq: "background/Expertise_Header.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, height: 1080)
      }
    }
  }
`
