import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { Content, SwipeContainer } from "./layout-components"
import { bp, colors, fonts } from "./Styles"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { useLottie, Lottie } from "react-lottie-hook"
import useComponentSize from "@rehooks/component-size"
import { ParallaxProvider, Parallax } from "react-scroll-parallax"
import FadeInSection from "../util/fade-in-section"

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 110rem;
  max-width: 100%;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
`

export const ImageCard = styled.div`
  flex: 0 0 auto;
  height: 24.3rem;
  background-color: ${colors.blue};
  width: 17rem;
  box-shadow: 0px 0px 20px #00000030;
  display: flex;
  flex-direction: column;
  margin: 1.5rem;

  ${bp("hdpi")`
  margin: 2.5rem;
  `}

  ${bp("ldpi")`
  margin: 2rem 3rem;
  `}
  & p {
    color: ${colors.primary};
  }
  > .lottie-container {
    margin: 1rem 1rem 0 1rem;
    height: 13rem;
    width: calc(100% - 2rem);

    > .lottie-sizer {
      width: 100%;
      height: 100%;
    }
  }

  > :last-child {
    margin-top: auto;
    line-height: 2.25rem;
    font-size: 1.22rem;
    padding: 1.4rem;
    padding-bottom: 1.5rem;
  }

  ${bp("mobile")`
    height: 27rem;
    width: 18.9rem;
    margin: 25px;
    position: relative;
    ::after {
      content: "";
      position: absolute;
      top: 0;
      left: -25px;
      right: -25px;
      bottom: 0;
    }

  > :first-child {
    margin-top: 2rem;
    height: 15rem;
  }

    > :last-child {
      padding: 1.1rem;
      padding-bottom: 1.4rem;
      font-size: 1.45rem;
      line-height: 2.4rem;
    }
  `}
`

const CardContainerInner = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  ${bp("ldpi")`
    width: 80rem;
    max-width: 100%;
    flex-wrap: wrap;
  `}
`

const CardsContainerOuter = styled.div`
  margin-top: -6rem;
  transform: translateY(7rem);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
  background: linear-gradient(0deg, ${colors.primary} 40%, rgb(0 0 0 / 0%) 40%);
  padding-bottom: 5em;

  > div ${Content} {
    > h2 {
      margin-bottom: 5.1rem;
      margin-top: 10.4rem;
      max-width: 100%;
      line-height: 4.3rem;
      font-size: 4.3rem;
      font-family: ${fonts.bold};
      color: ${colors.primary};

      ${bp("mobile")`
        display: none;
        `}
    }
  }

  ${bp("mobile")`
    background: linear-gradient(
      0deg, ${colors.primary} 60%, rgb(0 0 0 / 0%) 40%);
    margin-top: 0;
    margin-bottom: 12rem;
    transform: translateY(8rem);
  `}

  .expertise {
    p {
      font-family: ${fonts.bold};
      margin-top: 0;
    }
    ${ImageCard} {
      height: 20rem;
      .lottie-container {
        height: 9rem;
      }
    }
  }
`

interface CardItem {
  animation: any
  label: string
}

const CardInner: React.FC<{
  item: CardItem
  isSlider?: boolean
  isPlaying?: boolean
}> = ({ item, isSlider, isPlaying }) => {
  const { animation, label } = item
  const lottieSizerRef = useRef<HTMLDivElement>(null)
  const { width, height } = useComponentSize(lottieSizerRef)

  const [lottieRef, , controls] = useLottie({
    loop: true,
    autoplay: false,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid",
    },
  })

  useEffect(() => {
    if (isSlider && !isPlaying) controls.goToAndStop(0)
    if (isSlider && isPlaying) controls.play()
  }, [isPlaying, isSlider])

  return (
    <ImageCard
      onMouseEnter={() => controls.play()}
      onMouseLeave={() => controls.goToAndStop(0)}
    >
      <div className="lottie-container">
        <div className="lottie-sizer" ref={lottieSizerRef}>
          <Lottie {...{ width, height, lottieRef }} />
        </div>
      </div>

      <p>{label}</p>
    </ImageCard>
  )
}

const Cards: React.FC<{
  items: CardItem[]
  title?: string
  className?: string
  id?: string
}> = ({ items, title, className, id }) => {
  const breaks = useBreakpoint()
  const [activeSlide, setActiveSlide] = useState(0)

  const isSlider =
    breaks.mobile &&
    (items.length > 3 ||
      (items.length === 3 && breaks.widthMax710) ||
      breaks.widthMax420)

  const children = items.map((item, key) => {
    const isPlaying = key === activeSlide
    return <CardInner {...{ item, key, isSlider, isPlaying }} />
  })

  return (
    <ParallaxProvider>
      <CardsContainerOuter id={id}>
        <FadeInSection speed={0.1}>
          <Parallax y={[20, -10]}>
            {title && (
              <Content>
                <h2>{title}</h2>
              </Content>
            )}

            {isSlider && (
              <SwipeContainer
                {...{ className }}
                onSwitchActive={s => setActiveSlide(s)}
                center
              >
                {children}
              </SwipeContainer>
            )}

            {!isSlider && (
              <CardContainer>
                <CardContainerInner {...{ id, className }}>
                  {children}
                </CardContainerInner>
              </CardContainer>
            )}
          </Parallax>
        </FadeInSection>
      </CardsContainerOuter>
    </ParallaxProvider>
  )
}

export default Cards
